import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '',
    scrollBehavior: () => ({
        y: 0
    }),
    routes: [{
        path: '/',
        component: () => import('./Home.vue')
    },{
        path: '/thumbnail',
        component: () => import('./components/thumbnail.vue')
    },{
        path: '/test',
        component: () => import('./components/test.vue')
    }]

})

export default router